@import 'style/wegaw.module.scss';
@import 'style/app.module.scss';

@import 'style/app.scss';

$border-color: rgba(138,180,248,.24);

.catchmentList {
    border: 1px;
    border-color: $border-color;
    border-style: solid;
    padding-top: 2px;
    padding-bottom: 2px;

    &:hover{
        background-color: $wegaw-lightblue;
    }
}

.catchmentListContainer {
    max-height: 100%;
    max-width: 100%;
    overflow: hidden scroll;
    text-align: center;
    width: 100%;
}

.customMenuContainer {
    overflow: hidden;
    margin: 0px 20px;
    background-color: $transparent;
    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 10px;
    border: 1px solid $border-color;
    height: 100%;
    width: 20%;
    background-color: $wegaw-darkblue;
}

.catchmentListInput {
    width: 89% !important;
}

.DropdownItemcatchmentListName {
    @extend .dropdown-item;
    color: $text-color;
    font-size: 18px;
    padding: 10px;
    text-align: left;
    white-space: normal;
    cursor: pointer;

    &:hover {
        background-color: $transparent;
        color: $text-color;
    }
}

.zoomButton {
    background-color: $transparent;
    border-color: $transparent;
    color: $text-color;
    position: relative;
    top: 5%;
}