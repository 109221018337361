@import 'style/app.module.scss';
@import 'style/wegaw.module.scss';

[aria-labelledby='usericon'] {
    span {
        display: flex;
        justify-content: center;
        text-align: center;
    }
}

.profile_icon {
    color: $text-color;
}

.signout_icon {
    color: $wegaw-darkblue;
}