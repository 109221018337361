@import 'style/wegaw.module.scss';

.catchmentMap {
    width: 80%; 
    padding-left: 20px;
}

.infoIcon {
    margin-top: 0.7rem;
}

.selectionPage {
    height: calc(100% - 111px);
    max-height: calc(100vh - 111px);
    width: 100%;
    display: flex;
    background-color: $wegaw-darkblue;
}