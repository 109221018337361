@import 'style/app.module.scss';
@import 'style/wegaw.module.scss';

.action_on_text {
    cursor: pointer; 
}

.contact_paragraph_container {
    align-items: center;
    display: flex; 
}

.footer {
    background-color: $wegaw-darkblue;
    color: $text-color;
    display: flex;
    height: 55px;
    justify-content: center;
}

.hyperlink {
    @extend .action_on_text;
    color: $text-color;
}