@import './wegaw.module.scss';

.btn-circle {
    width: 30px;
    height: 30px;
    padding-top:10px;
    border-radius: 10px;
    font-size: 12px;
    text-align: match-parent;
}

// Wegaw blue variant

.btn-wegaw-blue {
    --bs-btn-color: #fff;
    --bs-btn-bg: #{$wegaw-blue};
    --bs-btn-border-color: #285e8e;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #{$wegaw-blue};
    --bs-btn-hover-border-color: #{$wegaw-blue};
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #{$wegaw-blue};
    --bs-btn-active-border-color: #{$wegaw-blue};
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #636ff0;
    --bs-btn-disabled-border-color: #366691;
}

.btn-wegaw-blue:hover { 
    background-color: #4957f1 !important; // By default, bootstrap is not changing the color while hovering, so we need to do it manually
    color: #fff !important; // By default, bootstrap is not changing the color while hovering, so we need to do it manually
}

// Wegaw dark blue variant

.btn-wegaw-darkblue {
    --bs-btn-color: #fff;
    --bs-btn-bg: #{$wegaw-darkblue};
    --bs-btn-border-color: #242b7adb;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #baccd3;
    --bs-btn-hover-border-color: #bebfc0;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #aebec5;
    --bs-btn-active-border-color: #a5b3b9;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #626a75;
    --bs-btn-disabled-border-color: #626a75;
}

.btn-wegaw-darkblue:hover { 
    background-color: #{$wegaw-lightblue} !important; // By default, bootstrap is not changing the color while hovering, so we need to do it manually
    color: #fff !important; // By default, bootstrap is not changing the color while hovering, so we need to do it manually
}

// Graph variant

.btn-graph {
    --bs-btn-color: #000;
    --bs-btn-bg: #e4ebee;
    --bs-btn-border-color: #bebfc0;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #baccd3;
    --bs-btn-hover-border-color: #bebfc0;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #aebec5;
    --bs-btn-active-border-color: #a5b3b9;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #626a75;
    --bs-btn-disabled-border-color: #626a75;
}

.btn-graph:hover { 
    background-color: #baccd3 !important; // By default, bootstrap is not changing the color while hovering, so we need to do it manually
    color: #fff !important; // By default, bootstrap is not changing the color while hovering, so we need to do it manually
}