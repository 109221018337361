@import 'style/app.module.scss';
@import 'style/wegaw.module.scss';

.avgText {
    display: flex;
    align-items: center;
    margin: 0px;
}

.group {
    color: #000000;
    font-size: 16px;
}