.ag-header-cell-label {
    justify-content: center;
}

.pointer-element:hover {
    cursor: pointer;
}

.cellCenter .ag-cell-wrapper {
    justify-content: center;
  }