@import 'bootstrap';

@import './app.module.scss';
@import './buttons.scss';

html {
  height: 100%;
  width: 100%;
}

body {
  color: $text-color;
  display: grid;
  font-family: 'Saira', Helvetica, Arial, Lucida, sans-serif;
  height: 100%;
  margin: 0;
}

.border {
  border: 8px solid;
  display: grid;
  font-size: 1.6rem;
  min-height: 200px;
  padding: 1rem;
  place-items: center;
}

.centerElement {
  display: flex;
  text-align: center;
  justify-content: center;
}

.logout {
  color: rgb(69, 84, 245);
  padding: 2rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 5%;
  width: 25%;
}

.navbar_horizontal_margin {
  margin-left:10px;
  margin-right:10px;
}

// scroll 

::-webkit-scrollbar {
  width: 0px;
}

// Ag tablet container style

.ag-theme-quartz  {
  font-family: "Saira", sans-serif;
}

.ag-body-viewport {
  overflow-y: auto !important;
}

.ag-root-wrapper-body {
  max-height: calc(100vh - 200px)
}

.tableContainer {
  min-height: calc(100vh - 143px);
  max-height: calc(100vh - 143px);
}