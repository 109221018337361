@import 'style/wegaw.module.scss';

.notFound{
    color: $wegaw-blue;
    padding: 2rem;
    width: 50%;
    text-align: center;
    margin: 0 auto;
    margin-top: 5%;
}

.linkStyle {
    text-decoration: none;
}