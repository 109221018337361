@import 'style/wegaw.module.scss';

.dateContainer {
    color: $wegaw-darkblue;
    float: left;
}

.downloadContainer {
    height: calc(100% - 143px)
}

.toCalendar {
    padding-left: 100px;
}