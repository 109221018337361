@import 'style/app.module.scss';
@import 'style/wegaw.module.scss';

@import 'style/app.scss';

.input_dates_container {
    color: $wegaw-darkblue;
    margin: 10px;
}

.modal_button {
    @extend .mt-2;
}

.modal_header {
    background-color: $wegaw-darkblue;
    color: $text-color;
    justify-content: center !important;
}