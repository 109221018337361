@import 'style/app.module.scss';
@import 'style/wegaw.module.scss';

.navbar_container {
    @extend .navbar_border, .sticky-nav;
    background-color: $wegaw-darkblue;
    justify-content: space-between !important;
}

.navbar_border {
    padding-left: 8px !important;
    padding-right: 12px  !important;
}

.sticky-nav {
    position: sticky;
    top: 0;
    z-index: 2;
}