@import 'style/wegaw.module.scss';

@import 'style/app.scss';

.icon {
    @extend .mb-1;
}

.hyperlink {
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.offcanvas {
    max-width: 20%; 
    min-width: 175px;
}

.poweredby {
    font-size: 12px;
}

.sidebar_row {
    color: $wegaw-blue !important;
}
